import React from "react";
import { PageLayout } from "../components/page-layout";

export const HomePageAdmin = () => {
  
  return (
    <PageLayout>
    </PageLayout>
  );
};
