import React from "react";
import { PageLayout } from "../components/page-layout";

export const SearchProfessor = () => {
  return (
    <PageLayout>
      <h1 id="page-title" className="content__title">
        Buscar docente
      </h1>
    </PageLayout>
  );
};